import React from 'react';
import { FaCheckCircle } from 'react-icons/fa'; // Иконка успешного завершения
import { motion } from 'framer-motion';
import Button from "../components/ui/Button";
import useMediaQueary from "../hooks/useMediaQueary"; // Анимации

function Thanks() {
  const isMob = useMediaQueary(false, '(max-width: 600px)')

  return (
    <motion.div
      className="min-h-screen flex flex-col justify-center relative items-center bg-gradient-to-br from-purple-600 to-blue-400 text-black"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.6 }}
    >
      {isMob
        ? <img src="/back.png" className={"max-h-screen object-cover absolute opacity-20 top-0 left-0 w-screen object-top"} alt=""/>
        : <img src="/back_desc.png" className={"max-h-[100vh] min-h-[100vh] absolute opacity-20 top-0 left-0 w-screen object-cover object-top"} alt=""/>
      }
      <div className="bg-white rounded-lg shadow-lg p-10 relative z-10 text-center text-gray-800 max-w-lg d-flex justify-center">
        <motion.div
          className="mb-5 mx-auto d-flex justify-center"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.6 }}
        >
          <FaCheckCircle className="text-green-500 text-6xl mx-auto" />
        </motion.div>

        <h1 className="text-3xl font-bold mb-4">Thank You!</h1>
        <p className="text-lg">
          Your submission has been received. We appreciate your time and will get back to you shortly!
        </p>

        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="mt-6 px-6 py-3 font-semibold rounded-lg  hover:bg-green-400 transition-all"
          onClick={() => window.location.href = '/'} // Кнопка вернет пользователя на главную
        >
          <Button label={'Back to Home'}> </Button>
        </motion.button>

      </div>
    </motion.div>
  );
}

export default Thanks;
