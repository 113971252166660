import React from 'react';
import clsx from "clsx";

function SectionInfo({children, title, classNames}) {
  return (
    <div className={clsx("text-center", classNames)}>
      <div className={"uppercase text-gray md:mb-[20px] mb-[8px]"}>{title}</div>
      <p className={"max-w-[1080px] mx-auto"}>
        {children}
      </p>
    </div>
  );
}

export default SectionInfo;