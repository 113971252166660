import React from 'react';
import {handleScroll} from "../../utils/handleScroll";
import Socials from "./Socials";
import CreatedAt from "./CreatedAt";
import {useTranslation} from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const navItems = [
    {
      to: 'about',
      name: "about",
    },
    {
      to: 'accounting',
      name: "accounting",
    },
    {
      to: 'ourServices',
      name: 'ourServices'
    },
    {
      to: "why",
      name: "whyUs"
    },
  ];


  return (
    <footer className="mt-[100px] md:pb-[40px] pb-[20px]">
      <div className={"bg-black md:py-[25px] smm:py-[15px] py-[10px] text-white flex justify-center lg:gap-[45px] md:gap-[30px] gap-[14px]"}>
        {navItems.map((item, i) => (
          <a
            onClick={(e) => handleScroll(e, item.to)}
            className={"font-black uppercase lg:text-[24px] md:text-[20px] smm:text-[16px] text-[11px]"} key={item.name} href={item.to}>
            {t(item.name)}
          </a>
        ))}
      </div>
      <div className={'container pt-[20px]'}>
        <div className={"flex justify-between"}>
          <img src="/logo.png" className={"lg:max-w-[225px] md:max-w-[160px] max-w-[120px] object-contain"} alt=""/>
          <div className={"md:flex items-end hidden"}>
            <CreatedAt />
          </div>
          <Socials />
        </div>
        <div className={"md:hidden flex justify-center pt-[12px]"}>
          <CreatedAt />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
