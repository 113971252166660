import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Home from "./pages/Home";
import AnimatedCursor from "react-animated-cursor";
import {useState} from "react";
import FormModal from "./components/core/FormModal";
import Thanks from "./pages/Thanks";

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1280);
  const [showFormModal, setShowFormModal] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setShowFormModal(true)
  }

  return (
    <Router className={'App'}>
      <Routes>
        <Route path="/" element={<Home openModal={openModal}/>} />
        <Route path="/thanks" element={<Thanks/> } />
      </Routes>

      <FormModal show={showFormModal} onClose={() => setShowFormModal(false)} />

      {isDesktop && (
        <AnimatedCursor
          innerSize={18}
          outerSize={18}
          color='230,166,33'
          outerAlpha={0.4}
          innerScale={0.8}
          outerScale={2}
          outerStyle={{
            border: '2px solid #fff',
            mixBlendMode: 'exclusion'
          }}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.swiper-pagination-bullet',
            '.link'
          ]}
          innerStyle={{
            backgroundColor: 'rgba(247,248,253,0.29)',
            border: '2px solid #205EFF',
          }}
        />
      )}
    </Router>
  );
}

export default App;
