import React from 'react';
import SectionInfo from "../components/core/SectionInfo";
import Prices from "./Prices";
import {useTranslation} from "react-i18next";

function Accounting(props) {
  const { t } = useTranslation();

  return (
    <div id={'accounting'} className={"md:pt-[150px] pt-[80px]"}>
      <div className="container">
        <h2 className={"text-center mb-[40px]"}>{t('AccountingTitle')}</h2>
        <SectionInfo title={t('plans')}>
          {t('AccountingText')}
        </SectionInfo>
      </div>
      <Prices />
    </div>
  );
}

export default Accounting;