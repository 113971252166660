import React from 'react';

function GetStart2(props) {
  return (
    <svg className={"w-full"} viewBox="0 0 178 178" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M89.0375 177.537C77.1075 177.537 65.5526 175.211 54.3728 170.635C44.0184 165.983 34.3393 159.83 26.3109 151.802C18.2075 143.698 12.0549 134.094 7.47796 123.74C2.82599 112.485 0.5 100.93 0.5 88.9999C0.5 77.0699 2.82599 65.515 7.40293 54.3352C12.0549 43.9808 18.2075 34.3768 26.3109 26.2733C34.4144 18.1699 44.0184 12.0173 54.3728 7.44036C65.5526 2.78839 77.1075 0.462402 89.0375 0.462402C100.968 0.462402 112.522 2.78839 123.702 7.36533C134.057 12.0173 143.736 18.1699 151.764 26.1983C159.868 34.3017 166.395 43.9058 170.597 54.2602C175.249 65.0648 177.5 76.6197 177.5 88.9249C177.5 100.855 175.174 112.41 170.597 123.59C165.945 133.944 159.792 143.623 151.764 151.651C143.661 159.755 134.057 166.283 123.702 170.484C112.522 175.211 100.968 177.537 89.0375 177.537ZM89.0375 8.19068C44.3936 8.19068 8.22827 44.356 8.22827 88.9999C8.22827 133.644 44.3936 169.809 89.0375 169.809C133.681 169.809 169.847 133.644 169.847 88.9999C169.472 44.356 133.306 8.19068 89.0375 8.19068Z"
        fill="#293041"/>
      <path
        d="M88.6624 98.6043C69.004 98.6043 52.8722 82.3974 52.8722 62.8141C52.8722 43.1558 69.0791 27.0239 88.6624 27.0239C108.321 27.0239 124.453 43.1558 124.453 62.8141C124.453 82.4724 108.696 98.6043 88.6624 98.6043ZM88.6624 34.7522C73.2808 34.7522 60.5254 47.4326 60.5254 62.8141C60.5254 78.1956 73.2058 90.876 88.5873 90.876C103.969 90.876 116.649 78.1956 116.649 62.8141C116.724 47.4326 104.044 34.7522 88.6624 34.7522Z"
        fill="#293041"/>
      <path
        d="M36.6653 152.477L28.937 151.727C28.937 150.977 30.4377 136.721 38.166 122.09C42.8179 113.236 48.9705 106.333 56.2486 101.306C65.4775 95.1531 76.2821 91.7017 88.9625 91.7017C101.268 91.7017 112.447 94.778 121.676 100.931C129.029 105.958 135.182 112.485 139.759 120.964C147.862 135.22 148.988 149.476 148.988 149.851L141.26 150.602C141.26 150.602 140.134 137.546 132.781 124.416C123.177 107.833 108.546 99.3549 88.8874 99.3549C69.2291 99.3549 54.2228 108.209 44.9938 125.166C37.8658 139.047 36.6653 152.102 36.6653 152.477Z"
        fill="#293041"/>
    </svg>
  );
}

export default GetStart2;