import React, {useEffect} from 'react';
import clsx from "clsx";
import Footer from "../components/core/Footer";
import {Route, useLocation} from "react-router-dom";
import Main from "../blocks/Main";
import Header from "../components/core/Header";
import AboutUs from "../blocks/AboutUs";
import OurServices from "../blocks/OurServices";
import Accounting from "../blocks/accounting";
import HowStart from "../blocks/HowStart";
import OurClients from "../blocks/OurClients";

const useHash = () => {
  const location = useLocation();
  return location.hash;
};

function Home({openModal}) {
  const hash = useHash()

  useEffect(() => {
    if(hash){
      const section = document.getElementById(hash.slice(1));
      if (section) {
        setTimeout(() => {
          section.scrollIntoView({ behavior: 'smooth' });
        }, 400)
      }
    }
  }, []);
  return (
    <div className={clsx('relative  flex flex-col')}>
      <Header />
      <Main openModal={openModal}/>
      <AboutUs />
      <OurServices />
      <Accounting />
      <HowStart openModal={openModal}/>
      {/*<OurClients />*/}
      <Footer />

      {/*<Footer />*/}
    </div>
  );
}

export default Home;