import React, {useState} from 'react';
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import { useSpring, animated } from '@react-spring/web';
import useMediaQueary from "../hooks/useMediaQueary";

function Item({ title, children, size = 'lg', colorHover }) {
  const [hovered, setHovered] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const isTable = useMediaQueary(false, '(max-width: 1280px)')

  const { width, height } = useSpring({
    width: hovered ? `1500px` : `0px`,
    height: hovered ? `1500px` : `0px`,
    config: { tension: 300, friction: 15, duration: 500 },
  });

  const handleMouseEnter = (e) => {
    if(isTable){
      return
    }
    setHovered(true);
    const rect = e.currentTarget.getBoundingClientRect();
    setCoords({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleMouseLeave = (e) => {
    if(isTable){
      return
    }
    const rect = e.currentTarget.getBoundingClientRect();
    setCoords({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
    setHovered(false);
  };

  const textC = {
    'sm': "xl:text-[32px] md:text-[21px] smm:text-[15px] text-[12px]",
    'lg': "xl:text-[40px] md:text-[25px] smm:text-[18px] text-[14px]"
  }


  return (
    <div
      className={clsx("relative bg-black text-white xl:hover:text-black transition-all duration-300 overflow-hidden xl:p-[40px] md:p-[30px] p-[20px] rounded-base ",{
        "bg-[#29304100]": hovered,
        "bg-black": !hovered
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="relative z-10">
        <h4
          className={clsx("xl:mb-[40px] md:mb-[20px] mb-[10px] transition-all duration-700", textC[size])}
        >{title}</h4>
        <div className="xl:text-[20px] md:text-[15px] smm:text-[11px] text-[10px] leading-snug transition-all duration-700">
          {children}
        </div>
      </div>
      {!isTable
        && <animated.div
          className="absolute  w-[200px] h-[200px] translate-x-[-50%] translate-y-[-50%] z-[5] rounded-full"
          style={{
            background: colorHover,
            width: width,
            height: height,
            top: coords.y,
            left: coords.x,
            pointerEvents: 'none', // Ensure that background does not interfere with hover
          }}
        ></animated.div>

      }

    </div>
  );
}

function OurServices(props) {
  const {t} = useTranslation();

  const colorHovers = [
    "#DBE4FF",
    "#FFE8DC",
    "#FFDDDB",
    "#E3FFE6",
    "#E3FFF8",
    "#FAFFE0",
  ]

  return (
    <div className={'container lg:pt-[150px] pt-[60px]'} id={'ourServices'}>
      <h2 className={"lg:mb-[40px] mb-[15px]"}>{t('ourServices')}</h2>
      <div className={"grid smm:grid-cols-3 sm:grid-cols-2 md:gap-[20px] smm:gap-[10px] gap-[5px]"}>
      <Item colorHover={colorHovers[0]} title={t('os_1_title')}>{t('os_1_text')}</Item>
        <Item colorHover={colorHovers[1]} title={t('os_2_title')}>{t('os_2_text')}</Item>
        <Item colorHover={colorHovers[2]} title={t('os_3_title')}>{t('os_3_text')}</Item>
        <Item colorHover={colorHovers[3]} title={t('os_4_title')}>{t('os_4_text')}</Item>
        <Item colorHover={colorHovers[4]} size={'sm'} title={t('os_5_title')}>{t('os_5_text')}</Item>
        <Item colorHover={colorHovers[5]} title={t('os_6_title')}>{t('os_6_text')}</Item>
      </div>
    </div>
  );
}

export default OurServices;