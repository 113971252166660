import React from 'react';
import clsx from "clsx";

function Socials({classes}) {
  const classNames = 'hover:opacity-[.7] transition'
  return (
    <div className={clsx("flex items-center gap-[20px]", classes)}>
      <a target='_blank' href="https://api.whatsapp.com/send/?phone=13105978371&text&type=phone_number&app_absent=0" className={"md:max-w-[36px] max-w-[32px]"}> <img className={clsx(classNames, 'object-contain')} src={'/wa.png'} alt={'icon'}/></a>
      <a target='_blank' href="https://t.me/nexusacc82" className={"md:max-w-[40px] max-w-[34px]"}> <img className={clsx(classNames, 'object-contain')} src={'/tg.png'} alt={'icon'}/></a>
      <a  target='_blank' href="https://www.instagram.com/nexusaccountingcorp?igsh=MnVrdWtlb3Fnd2Uy" className={"md:w-[40px] w-[34px]"}>
        <svg className={"md:w-[40px] w-[34px]"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>instagram</title><path fill="#293041" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" /></svg>
      </a>
    </div>
  );
}

export default Socials;