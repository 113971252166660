import React from 'react';
import Button from "../components/ui/Button";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import useMediaQueary from "../hooks/useMediaQueary";

function Main({openModal}) {
  const { t, i18n } = useTranslation();
  const isMob = useMediaQueary(false, '(max-width: 600px)')

  const currentLanguage = i18n.language; // Текущий язык

  return (
    <div className="Main flex flex-col relative ">
      {isMob
        ? <img src="/back.png" className={"max-h-screen object-cover object-top"} alt=""/>
        : <img src="/back_desc.png" className={"max-h-[100vh] min-h-[100vh] object-cover object-top"} alt=""/>
      }
      <div className="absolute bottom-0 left-0 z-10 w-full main-text pb-[20px] pt-[50px]">

        <div className="container text-center flex flex-col items-center ">
          <h1
            className={clsx(currentLanguage, 'font-black')}
          >{t('title')}</h1>
          <Button label={t('getStarted')} onClick={openModal} className={"mt-[30px]"}></Button>
        </div>
      </div>
    </div>
  );
}

export default Main;