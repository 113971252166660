import React, {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';
import clsx from 'clsx';
import {z} from 'zod';
import Button from '../ui/Button';
import {useTranslation} from 'react-i18next';
import {useSpring, useTransition, animated} from '@react-spring/web';

const botToken = '7366050348:AAGsLjJL0_hGGCrCaX788AzcIcjRRAVqWCM';
const chatId = '-1002183347088';

// Схема валидации с использованием zod
const formSchema = z.object({
  name: z.string().min(2, {message: 'validation.name'}),
  phone: z.string().regex(/^\+1 \(\d{3}\)-\d{3}-\d{4}$/, {message: 'validation.phone'}),
  email: z.string().email({message: 'validation.email'}),
  message: z.string(),
});

function FormModal({show, onClose}) {
  const {t} = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [statusMessage, setStatusMessage] = useState('error');
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);

  const validateForm = () => {
    try {
      formSchema.parse(formData);
      setErrors({});
      return true;
    } catch (e) {
      const fieldErrors = {};
      for (const key in e.formErrors.fieldErrors) {
        fieldErrors[key] = t(e.formErrors.fieldErrors[key][0]);
      }
      setErrors(fieldErrors);
      return false;
    }
  };

  useEffect(() => {
    setIsShowMessage(false)

    if (mounted) {
      validateForm();
    }
    setMounted(true);
  }, [formData]);

  const modalSpring = useSpring({
    opacity: show ? 1 : 0,
    transform: show ? 'scale(1)' : 'scale(0.9)',
    config: {tension: 300, friction: 20}
  });


  if (!show) return null;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const resetForm = () => {
    setFormData({
      name: '',
      phone: '',
      email: '',
      message: ''
    });
    setErrors({});
  };


  const handleClose = () => {
    onClose()
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const setError = () => {
      setStatusMessage('error');
      setIsShowMessage(true);
      setTimeout(() => {
        setIsShowMessage(false);
      }, 3000);
    };

    const setSuccess = () => {
      setStatusMessage('success');
      setIsShowMessage(true);
      setTimeout(() => {
        window.location.href = '/thanks.html'
      }, 1000)
      setTimeout(() => {
        setIsShowMessage(false);
      }, 3000);
    };

    try {
      const message = `Name: ${formData.name}\nPhone: ${formData.phone}\nMessage: ${formData.message}\nEmail: ${formData.email}`;
      const res = await fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: message
        })
      });

      if (res.status !== 200) {
        setError();
        return;
      }
      setSuccess();
      setTimeout(() => {
        handleClose()
      }, 2500)
    } catch (err) {
      setError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Фон затемнения */}
      <div className="fixed inset-0 bg-black opacity-50" onClick={handleClose}></div>

      {/* Само модальное окно */}
      <animated.div style={modalSpring}
                    className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6 relative z-10 mx-[15px]">
        <button
          className="absolute top-3 right-3 text-[30px] text-gray-500 hover:text-gray-700"
          onClick={handleClose}
        >
          &times;
        </button>

        <form onSubmit={handleSubmit} className="flex flex-col gap-4 pt-[30px]">
          <div>
            <input
              type="text"
              className={clsx('input', {'!border-red': errors.name})}
              placeholder={t('form.namePlaceholder')}
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <p className="text-red text-sm mt-1">{errors.name}</p>}
          </div>

          <div>
            <input
              type="text"
              className={clsx('input', {'!border-red': errors.email})}
              placeholder={t('form.emailPlaceholder')}
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="text-red text-sm mt-1">{errors.email}</p>}
          </div>

          <div>
            <InputMask
              mask="+1 (999)-999-9999"
              value={formData.phone}
              onChange={handleChange}
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  className={clsx('input', {'!border-red': errors.phone})}
                  type="text"
                  placeholder={t('form.phonePlaceholder')}
                  name="phone"
                  required
                />
              )}
            </InputMask>
            {errors.phone && <p className="text-red text-sm mt-1">{errors.phone}</p>}
          </div>

          <div>
            <textarea
              className={clsx('textarea', {'!border-red': errors.message})}
              placeholder={t('form.messagePlaceholder')}
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
            {errors.message && <p className="text-red text-sm mt-1">{errors.message}</p>}
          </div>

          <Button
            type="submit"
            disabled={loading}
            className="w-full !text-[22px] !rounded-[15px] !py-[12px]"
            label={loading ? t('form.loading') : t('form.submit')}
          ></Button>
        </form>

      </animated.div>

      <div
        className={clsx(
          'message fixed transition-all w-[310px] duration-500 top-[10px] text-[18px] text-white py-2 px-5 rounded-lg left-1/2 translate-x-[-50%]',
          {
            'translate-y-0': isShowMessage,
            'translate-y-[-200px]': !isShowMessage,
            'shadow-custom-green bg-green': statusMessage === 'success',
            'bg-red': statusMessage === 'error',
          }
        )}
      >
        {statusMessage === 'success' ? t('form.successMessage') : t('form.errorMessage')}
      </div>
    </div>
  )
}

export default FormModal;
