import React from 'react';

function GetStart1(props) {
  return (
    <svg className={"w-full"} viewBox="0 0 178 178" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M89 177.5C77.075 177.5 65.45 175.175 54.575 170.525C44 166.1 34.55 159.725 26.45 151.55C18.35 143.45 11.975 133.925 7.475 123.425C2.825 112.475 0.5 100.925 0.5 89C0.5 77.075 2.825 65.45 7.475 54.575C11.9 44 18.275 34.55 26.45 26.45C34.55 18.275 44 11.9 54.575 7.475C65.525 2.825 77.075 0.5 89 0.5C100.925 0.5 112.55 2.825 123.425 7.475C134 11.9 143.45 18.275 151.55 26.45C159.65 34.55 166.025 44.075 170.525 54.575C175.175 65.525 177.5 77.075 177.5 89C177.5 100.925 175.175 112.55 170.525 123.425C166.1 134 159.725 143.45 151.55 151.55C143.45 159.65 133.925 166.025 123.425 170.525C112.55 175.175 100.925 177.5 89 177.5ZM89 8.225C44.45 8.225 8.225 44.45 8.225 89C8.225 133.55 44.45 169.775 89 169.775C133.55 169.775 169.775 133.55 169.775 89C169.775 44.45 133.55 8.225 89 8.225Z"
        fill="#293041"/>
      <path
        d="M103.475 63.1247H62.825C60.65 63.1247 58.925 61.3997 58.925 59.2247C58.925 57.0497 60.65 55.3247 62.825 55.3247H103.55C105.725 55.3247 107.45 57.0497 107.45 59.2247C107.45 61.3997 105.65 63.1247 103.475 63.1247ZM103.475 94.2497H62.825C60.65 94.2497 58.925 92.5247 58.925 90.3497C58.925 88.1747 60.65 86.4497 62.825 86.4497H103.55C105.725 86.4497 107.45 88.1747 107.45 90.3497C107.375 92.5247 105.65 94.2497 103.475 94.2497ZM85.025 110.975H62.825C60.65 110.975 58.925 109.25 58.925 107.075C58.925 104.9 60.65 103.175 62.825 103.175H85.025C87.2 103.175 88.925 104.9 88.925 107.075C88.925 109.25 87.125 110.975 85.025 110.975ZM98.225 79.9247H62.825C60.65 79.9247 58.925 78.1997 58.925 76.0247C58.925 73.8497 60.65 72.1247 62.825 72.1247H98.225C100.4 72.1247 102.125 73.8497 102.125 76.0247C102.125 78.1247 100.4 79.9247 98.225 79.9247Z"
        fill="#293041"/>
      <path
        d="M118.475 110.375V130.1H54.275V48.5747H118.475V84.7997L125.3 78.8747V42.1997H47.45V136.4H125.225V105.05L118.475 110.375ZM132.125 75.8747L127.775 79.7747L137.075 88.1747L141.425 84.1997L132.125 75.8747ZM105.65 99.7997L114.95 108.2L136.025 89.0747L126.725 80.7497L105.65 99.7997ZM113.375 109.7L104.075 101.3L103.1 102.2L100.475 113L112.4 110.6L113.375 109.7Z"
        fill="#293041"/>
    </svg>
  );
}

export default GetStart1;