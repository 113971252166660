import React, {useState, useRef} from 'react';
import {useSpring, animated} from '@react-spring/web';
import clsx from 'clsx';
import {useTranslation} from "react-i18next";

function PriceItem({title, keyTab, fill, text, active}) {
  const [show, setShow] = useState(false);
  const headerRef = useRef();
  const infoRef = useRef();

  const expandAnimation = useSpring({
    from: {height: 0, opacity: 0},
    to: {
      height: show ? infoRef.current?.scrollHeight : 0,
      opacity: show ? 1 : 0,
    },
    config: {tension: 300, friction: 20},
  });

  const toggleAccordion = () => setShow(!show);
  const lines = text.trim().split('\n').map(line => line.trim());

  return (
    <div
      onClick={toggleAccordion}
      className={clsx(
        'md:rounded-base rounded-[10px]',
        {
          'bg-black text-white': fill,
          'border border-1 border-lightGray': !fill,
        }
      )}
    >
      <div
        ref={headerRef}
        className="md:py-[40px] py-[13px] flex justify-center items-end text-center link"
      >
        <div className="md:min-w-[420px] ">
          <div className="clamp4016 font-black inline-block">
            <span className="mr-[20px] inline-block">{!show ? '+' : '-'}</span> {title}
          </div>
          <span className="md:text-[20px] ml-1  inline-block ">
            {keyTab}
          </span>
        </div>
      </div>

      {/* Animated content area */}
      <animated.div
        ref={infoRef}
        style={expandAnimation}
        className="overflow-hidden"
      >
        <div className="pb-[20px] text-left">
          <div className={"lg:text-[24px] leading-snug max-w-[1000px] mx-auto px-[15px] flex flex-col gap-[10px]  md:text-[20px] text-[14px]"}>
            {lines.map(line => (
              <p>{line}</p>
            ))}
          </div>
        </div>
      </animated.div>
    </div>
  );
}

function PricesItems({data, keyTab}) {
  const { t, i18n } = useTranslation();

  
  return (
    <div>
      <div className={"flex flex-col md:gap-[20px] gap-[8px]"}>
        {data.base.map((item, index) => (
          <PriceItem title={item.title} text={item.text} key={index} keyTab={keyTab}/>
        ))}
      </div>
    </div>
  )
}

function Prices(props) {
  const [tab, setTab] = React.useState(0);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language; // Текущий язык

  // Получение переводов для текущего языка
  const translations = i18n.getResourceBundle(currentLanguage, 'translation')

  const dataEn = [
    {
      base: [
        { title: 'Basic $350/', text: `
            Support :
            Email support 
            Monthly meetings and reviews 
            Bookkeeping : 
            Set up books in Qbs online or Desktop
            QBs subscription 
            Tracking categories checking / saving accounts and credit cards
            Monthly reconciliation 
            Journal entries depreciation, amortization , fixed assets , current and other assets, current and long term liabilities 
            Preparation of financial statements on yearly basis
          ` },
        { title: 'Standart $440/', text: `
            Support :
Email support 
Dedicated contact 5 days a week 
Monthly meetings and reviews 
Bookkeeping : 
Set up books in Qbs online or Desktop
QBs subscription 
Tracking categories checking / saving accounts and credit cards
Monthly reconciliation 
Journal entries depreciation, amortization , fixed assets , current and other assets, current and long term liabilities 
Preparation of financial statements on twice a year ( every six months)
          ` },
        { title: 'Premium $800/', text: `
          Support :
Email support 
Dedicated contact 5 days a week 
Weekly  meetings and reviews 
Bookkeeping : 
Set up books in Qbs online or Desktop
QBs subscription 
Tracking categories checking / saving accounts and credit cards
Monthly reconciliation 
Journal entries depreciation, amortization , fixed assets , current and other assets, current and long term liabilities 
Preparation of financial statements on monthly basis 
Tax planning
` }
      ],
    },
    {
      base: [
        { title: 'Basic 2685$/', text: `
          Support :
Email support 
Dedicated contact of accountant 
Meetings with accountant per request 
Bookkeeping : 
Set up books in Qbs online or Desktop
QBs subscription 
Full analysis of transactions and accounts 
Tracking categories checking / saving accounts and credit cards 
 all year - monthly reconciliation 
Journal entries depreciation, amortization , fixed assets , current and other assets, current and long term liabilities 
Preparation of financial statements
`},
      ],
    }
  ]

  const dataRu = [
    {
      base: [
        {
          title: 'Полный пакет $350/',
          text: `
            Поддержка :
            Поддержка по электронной почте 
            Ежемесячные встречи и обзоры 
            Бухгалтерия : 
            Настройка книг в QBs онлайн или Desktop
            Подписка на QBs 
            Отслеживание категорий проверок / сберегательных счетов и кредитных карт
            Ежемесячное сверение 
            Записи в журнале: амортизация, износ, основные средства, текущие и другие активы, текущие и долгосрочные обязательства 
            Подготовка финансовых отчетов на ежегодной основе
          `
        },
        {
          title: 'Стандартный пакет $440/',
          text: `
            Поддержка :
            Поддержка по электронной почте 
            Посвященный контакт 5 дней в неделю 
            Ежемесячные встречи и обзоры 
            Бухгалтерия : 
            Настройка книг в QBs онлайн или Desktop
            Подписка на QBs 
            Отслеживание категорий проверок / сберегательных счетов и кредитных карт
            Ежемесячное сверение 
            Записи в журнале: амортизация, износ, основные средства, текущие и другие активы, текущие и долгосрочные обязательства 
            Подготовка финансовых отчетов дважды в год (каждые шесть месяцев)
          `
        },
        {
          title: 'Полный пакет $800/',
          text: `
            Поддержка :
            Поддержка по электронной почте 
            Посвященный контакт 5 дней в неделю 
            Еженедельные встречи и обзоры 
            Бухгалтерия : 
            Настройка книг в QBs онлайн или Desktop
            Подписка на QBs 
            Отслеживание категорий проверок / сберегательных счетов и кредитных карт
            Ежемесячное сверение 
            Записи в журнале: амортизация, износ, основные средства, текущие и другие активы, текущие и долгосрочные обязательства 
            Подготовка финансовых отчетов на ежемесячной основе 
            Налоговое планирование
          `
        }
      ],
    },
    {
      base: [
        {
          title: 'Базовый пакет $2685/',
          text: `
            Поддержка :
            Поддержка по электронной почте 
            Посвященный контакт с бухгалтером 
            Встречи с бухгалтером по запросу 
            Бухгалтерия : 
            Настройка книг в QBs онлайн или Desktop
            Подписка на QBs 
            Полный анализ транзакций и счетов 
            Отслеживание категорий проверок / сберегательных счетов и кредитных карт 
            В течение всего года - ежемесячное сверение 
            Записи в журнале: амортизация, износ, основные средства, текущие и другие активы, текущие и долгосрочные обязательства 
            Подготовка финансовых отчетов
          `
        }
      ]
    }
  ];
  

  const underLine = "absolute bottom-[-3px] duration-500 transition-all left-0 w-full h-[2px] "
  const keyTabs = ['monthly', 'annually']

  return (
    <div className={"lg:mt-[150px] md:mt-[100px] mt-[60px]"}>
      <div className="container">
        <div className={"flex justify-center items-center md:gap-[80px] gap-[25px] md:mb-[40px] mb-[18px]"}>
          <h4 onClick={() => setTab(0)}
               className={clsx(" font-black link transition-all relative", {"text-blue": tab === 0})}>
            {t('prices.monthly.title')}
            <span className={clsx(underLine, {'scale-0 bg-black': tab !== 0, 'scale-100 bg-blue': tab === 0})}></span>
          </h4>
          <h4 onClick={() => setTab(1)}
               className={clsx(" font-black link transition-all relative", {"text-blue": tab === 1})}>
            {t('prices.annually.title')}
            <span className={clsx(underLine, {'scale-0 bg-black': tab !== 1, 'scale-100 bg-blue': tab === 1})}></span>
          </h4>
        </div>

        <div>
          <PricesItems keyTab={tab === 0 ? t('priceMo') : t('priceY')} data={currentLanguage === 'en' ? dataEn[tab] : dataRu[tab]} key={tab}/>
        </div>
      </div>
    </div>
  );
}

export default Prices;