import React from 'react';
import {useTranslation} from "react-i18next";
import clsx from "clsx";

function LangSwitcher(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (lang) => {
    console.log('changeLanguage', lang);
    i18n.changeLanguage(lang);
  };

  const cls = "font-black transition"

  return (
    <div className={"flex gap-[20px] items-center"}>
      <button className={clsx(cls, {'opacity-50': currentLanguage !== 'en'})} onClick={() => changeLanguage('en')}>EN</button>
      <button className={clsx(cls, {'opacity-50': currentLanguage !== 'ru'})} onClick={() => changeLanguage('ru')}>RU</button>
    </div>
  );
}

export default LangSwitcher;