import React, {useEffect, useState} from 'react';
import {handleScroll} from "../../utils/handleScroll";
import Burger from "../ui/Burger";
import clsx from 'clsx'
import Socials from "./Socials";
import { Link } from "react-router-dom";
import LangSwitcher from "./LangSwitcher";
import {useTranslation} from "react-i18next";

const Header = ({transparent}) => {
  const { t, i18n } = useTranslation();
  const [isActiveBurger, setIsActiveBurger] = useState(false)
  const navItems = [
    {
      to: 'about',
      name: "about",
    },
    {
      to: 'accounting',
      name: "accounting",
    },
    {
      to: 'ourServices',
      name: 'ourServices'
    },
    {
      to: "why",
      name: "whyUs"
    },
  ];

  const handleClickLink = (e, to) => {
    handleScroll(e, to)
    setIsActiveBurger(false)
  }

  useEffect(() => {
    if (isActiveBurger) {
      document.querySelector('html').classList.add('blocked')
    } else {
      document.querySelector('html').classList.remove('blocked')
    }
  }, [isActiveBurger])

  return (
    <header
      className={clsx("absolute top-0 left-0 w-screen z-20")}
    >
      <div
        className={clsx("mx-auto  w-full flex py-[20px] gap-2 justify-between items-center container")}>
        <a href="#" className={"relative z-50"}>
          <img src="/logo.png"  className={"lg:max-w-[225px] max-w-[170px]"} alt=""/>
        </a>
        <nav
          className={clsx('flex xl:static  xl:pointer-events-auto z-40 point-event-none  fixed top-0 left-0 xl:w-fit w-full xl:flex-row flex-col items-center xl:pt-0 pt-[120px] top gap-[20px] transition-all duration-500', {
            "min-h-[100vh] opacity-100  leading-tight bg-[#E9EDF0] px-[15px]": isActiveBurger,
            "sm:min-h-[43px] min-h-[43px] rounded-base xl:opacity-100 opacity-0  xl:translate-y-0 translate-y-[-120%]": !isActiveBurger
          })}>
          <div className="xl:grow navItems flex xl:text-[24px] text-[44px] xl:flex-row flex-col justify-center text-center gap-[20px]">
            {navItems.map((item, index) => (
              <a onClick={(e) => handleClickLink(e, item.to)} key={index} href={`#${item.to}`}
                 className={clsx("uppercase font-black", {'active': isActiveBurger})}>
                {t(item.name)}
              </a>
            ))}
          </div>

          <div
            className={"w-full mt-[5vh] gap-[40px] flex justify-center items-center pb-[30px] container xl:hidden"}>
            <Socials/>
            <LangSwitcher />
          </div>
        </nav>

        <div className={'flex items-center xl:justify-between justify-end xl:ml-0 ml-auto xl:w-fit w-full '}>
          <div className={"xl:flex hidden"}>
            <Socials/>
            <div className={"ml-[20px]"}></div>
            <LangSwitcher />
          </div>
          <div className={'relative z-50 xl:hidden block'}>
            <Burger active={isActiveBurger} onClick={() => setIsActiveBurger(!isActiveBurger)}/>
          </div>
        </div>

      </div>
    </header>
  );
};

export default Header;
