import React from 'react';
import SectionInfo from "../components/core/SectionInfo";
import {useTranslation} from "react-i18next";
import useMediaQueary from "../hooks/useMediaQueary";

function AboutUs(props) {
  const { t } = useTranslation();
  const isMob = useMediaQueary(false, '(max-width: 600px)')

  return (
    <div id={'about'} className="lg:pt-[150px] pt-[80px]">
      <SectionInfo classNames={'container relative z-10'} title={t('about')}>
        {t('aboutText')}
      </SectionInfo>

      {!isMob
        ? <img src="/about.png"
               className={"xl:mt-[-30px] object-cover xl:max-w-fit xl:w-[1920px] relative left-1/2 translate-x-[-50%]"}
               alt=""/>
        : <img src="/about_mob.png" alt=""/>
      }
    </div>
  );
}

export default AboutUs;