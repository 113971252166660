import React, {useEffect, useRef} from 'react';
import clsx from "clsx";
import { motion } from "framer-motion";


function Button({children, type, disabled, label, onClick, className, color = 'red', full, size = 'def'}) {
  return (
    <button disabled={disabled} onClick={onClick} type={type} className={
      clsx(
        "bg-black  transition-all duration-500 border-2 border-black hover:text-blue text-white lg:px-[56px] px-[40px] lg:py-[22px] py-[15px] lg:rounded-base rounded-[20px] lg:text-[36px] text-[22px] font-bold",
        color,
        className,
        {
          "w-full": full,
          "w-fit": !full
        }
      )}>
      {label}
    </button>
  );
}

export default Button;