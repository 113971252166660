import React from 'react';
import GetStart1 from "../components/icon/GetStart1";
import GetStart2 from "../components/icon/GetStart2";
import GetStart3 from "../components/icon/GetStart3";
import clsx from "clsx";
import Button from "../components/ui/Button";
import {useTranslation} from "react-i18next";
import useMediaQueary from "../hooks/useMediaQueary";

function Item({title, children, titleClass, wrapClass}) {
  return (
    <div
      className={clsx(wrapClass, "border border-1 border-lightGray lg:px-[40px] px-[20px] lg:pt-[70px] md:pt-[40px] pt-[20px] flex flex-col items-center pb-[40px] md:rounded-base rounded-[13px]")}>
      <div className={clsx(titleClass, 'font-black lg:min-h-[142px] leading-tight min-h-[57px] mb-[30px]')} style={{lineHeight: 1.05}}>{title}</div>
      <div className={"xl:max-w-[180px] lg:max-w-[130px] max-w-[90px]"}>{children}</div>
    </div>
  )
}

function HowStart({openModal}) {
  const {t, i18n} = useTranslation();
  const isMob = useMediaQueary(false, '(max-width: 600px)')

  return (
    <div className={"howStart"}>
      <div className={"max-w-screen  overflow-hidden pb-[40px]"}>
        {
          isMob
            ? <img src="/acc_mob.png" className={"w-full "} alt=""/>
            : <img src="/acc.png" className={"w-full "} alt=""/>
        }

        <h2 className={"xl:mt-[-140px] lg:mt-[-100px] mt-[-50px] relative z-10 text-center"}>{t('howTitle')}</h2>
      </div>
      <div className={"grid md:grid-cols-3 grid-cols-2 md:gap-[20px] gap-[10px] container"}>
        <Item titleClass={'lg:text-[38px] md:text-[32px] text-[20px]'} title={t('how_txt_1')}><GetStart1/></Item>
        <Item titleClass={'lg:text-[34px] md:text-[28px] text-[18px]'} title={t('how_txt_2')}><GetStart2/></Item>
        <Item wrapClass={"col-span-2 md:col-span-1"} titleClass={'lg:text-[35px] md:text-[28px] text-[22px]'} title={t('how_txt_3')}><GetStart3/></Item>
      </div>
      <div className={"mx-auto w-fit md:mt-[40px] mt-[20px]"}>
        <Button onClick={openModal} label={t('getStarted')}/>
      </div>
    </div>
  );
}

export default HowStart;